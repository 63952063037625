@media screen and (min-width: 1441px) and (max-width: 1920px) {

  .cloud_left_mobile,
  .cloud_right_mobile,
  .cloud_top_mobile,
  .bamboo_small_left_mobile,
  .bamboo_small_right_mobile,
  .bridge-background-mobile,
  .textureMiddleMobile,
  .bamboo_big_left_mobile,
  .bamboo_big_right_mobile,
  .bridge-backgroundUltraWide,
  .textureRightUltra,
  .textureLeftUltra,
  .bamboo_big_left_1_ultra,
  .bamboo_big_right_1_ultra,
  .footer-mobile {
    display: none;
  }

  .background {
    width: 1920px;
    height: 6333px;
    overflow: hidden; 
    position: relative;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #496d20;
    border: 15px solid #496d20;
    border-radius: 10px;
    width: 1034px;
    height: 582px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform, opacity;
  }

  .modal-content.open {
    animation: fadeIn 0.5s ease forwards;
  }

  @keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    top: -60px;
    right: -60px;
    color: #496d20;
    font-size: 56px;
    font-weight: 300;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #273b10;
    text-decoration: none;
  }

  .backgroundBlue {
    width: 1920px;
    height: 980px;
    top: 0px;
    background-color: #3FD0FF;
    position: absolute;
    justify-content: center;
    left: 0;
    z-index: 1;
  }

  .backgroundGreen {
    width: 1920px;
    height: 5353px;
    top: 980px;
    position: absolute;
    left: 0;
    background-color: #68A025;
    justify-content: center;
    z-index: 1;
  }

  .cloud_left {
    width: 950px;
    height: 609.5px;
    top: 72px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: left, opacity;
    animation: slideCloudLeft 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudLeft {
    0% {
      left: 200px;
      opacity: 0;
    }
    100% {
      left: -507px;
      opacity: 1;
    }
  }

  .cloud_right {
    width: 945.58px;
    height: 615px;
    top: -14px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: left, opacity;
    animation: slideCloudRight 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudRight {
    0% {
      left: 700px;
      opacity: 0;
    }
    100% {
      left: 1528.5px;
      opacity: 1;
    }
  }

  .bamboo_small_left {
    width: 281.9px;
    height: 1083.79px;
    top: 0px;
    left: 0px;
    z-index: 3;
    will-change: left;
    position: absolute;
    pointer-events: none;
    animation: slideBambooSmallLeft 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooSmallLeft {
    0% {
      left: -300px;
    }
    100% {
      left: 0px;
    }
  }

  .bamboo_small_right {
    width: 284.01px;
    height: 1081.84px;
    position: absolute;
    top: 14.95px;
    left: 1640px;
    z-index: 3;
    will-change: left;
    pointer-events: none;
    animation: slideBambooSmallRight 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooSmallRight {
    0% {
      left: 1950px;
      opacity: 0;
    }
    100% {
      left: 1640px;
      opacity: 1;
    }
  }

  .bridge-background {
    width: 1920px;
    height: 263.5px;
    top: 919px;
    z-index: 3;
    position: absolute;
    pointer-events: none;
  }

  .textureLeft {
    width: 400px;
    height: 5225px;
    left: -10px;
    top: 1020px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
  }

  .textureMiddle {
    width: 1897.94px;
    height: 5400.85px;
    left: 11px;
    top: 1000px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
  }

  .textureRight {
    width: 400px;
    height: 5225px;
    left: 1550px;
    top: 1020px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
  }

  .bamboo_big_left_1 {
    width: 490px;
    height: 3000.51px;
    top: -30px;
    z-index: 3;
    will-change: left;
    position: absolute;
    pointer-events: none;
    animation: slideBambooBigLeft1 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooBigLeft1 {
    0% {
      left: -500px;
    }
    100% {
      left: -30px;
    }
  }

  .bamboo_big_right_1 {
    width: 490px;
    height: 3000.51px;
    top: -30px;
    z-index: 3;
    will-change: left;
    position: absolute;
    pointer-events: none;
    animation: slideBambooBigRight1 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooBigRight1 {
    0% {
      left: 2000px;
    }
    100% {
      left: 1470px;
    }
  }

  .bamboo_big_left_2 {
    width: 1765.39px;
    height: 6522.76px;
    top: 850px;
    left: -860px;
    z-index: 3;
    transform: rotate(1deg);
    position: absolute;
    pointer-events: none;
    transform-origin: center;
  }

  .bamboo_big_right_2 {
    width: 1700.76px;
    height: 6522.76px;
    left: 900px;
    top: 800px;
    z-index: 3;
    transform: rotate(0deg);
    position: absolute;
    pointer-events: none;
    transform-origin: center;
  }

  .windowTrailerVideo {
    width: 1034px;
    height: 594px;
    left: 443px;
    top: 2667px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
    transform-origin: center;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .windowTrailerVideo.visible {
    top: 2382px;
    opacity: 1;
  }

  .footer {
    width: 1920px;
    height: 716.79px;
    position: absolute;
    top: 6333px;
    z-index: 2;
    will-change: top, left;
    pointer-events: none;
    opacity: 0;
  }

  .footer.visible {
    animation: footer 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 1;
  }

  @keyframes footer {
    0% {
      top: 6333px;
    }
    100% {
      top: 5763px;
    }
  }
}
