/* Стили персонажей и их анимаций */
@media screen and (min-width: 1921px) {

  .character {
    width: 435px;
    height: 703px;
    position: absolute;
    left: 476px;
    animation: slideDownCharacter 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 5;
  }

  @keyframes slideDownCharacter {
    0% {
      top: -200px;
      opacity: 0;
    }
    100% {
      top: 316px;
      opacity: 1;
    }
  }

  .shadow {
    width: 352px;
    height: 65px;
    top: 985px;
    position: absolute;
    left: 517px;
    will-change: transform;
    pointer-events: none;
    animation: shadow 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    z-index: 2;
  }

  @keyframes shadow {
    0% {
      will-change: transform;
      transform: scale(0);
    }
    100% {
      will-change: transform;
      transform: scale(1);
    }
  }

  .startCharacter {
    width: 321.53px;
    height: 369.76px;
    position: absolute;
    top: 1920px;
    left: 443px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .startCharacter.visible {
    top: 1520px;
    opacity: 1;
  }

  .smashCharacter {
    width: 296.7px;
    height: 353.47px;
    position: absolute;
    left: 815px;
    top: 1845px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s, opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s;
  }

  .smashCharacter.visible {
    top: 1445px;
    opacity: 1;
  }

  .earnCharacter {
    width: 316.33px;
    height: 376.69px;
    position: absolute;
    left: 1170px;
    top: 1920px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s, opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .earnCharacter.visible {
    top: 1520px;
    opacity: 1;
  }

  .smashToEarnCharacter {
    width: 575.77px;
    height: 356px;
    position: absolute;
    left: -300px;
    top: 3630px;
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .smashToEarnCharacter.visible {
    left: 450px;
    opacity: 1;
  }

  .ffffToEarnCharacter {
    width: 579.95px;
    height: 378.94px;
    position: absolute;
    left: 878px;
    top: 4300px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .ffffToEarnCharacter.visible {
    top: 3975px;
    opacity: 1;
  }

  .playToEarnCharacter {
    width: 565.8px;
    height: 292.73px;
    position: absolute;
    left: -300px;
    top: 4679px;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .playToEarnCharacter.visible {
    left: 450px;
    opacity: 1;
  }
}
