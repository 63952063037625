@media screen and (min-width: 376px) and (max-width: 393px) {

  .character {
    width: 218.44px;
    height: 353px;
    position: absolute;
    left: 87.22px;
    animation: slideDownCharacter 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 5;
  }

  @keyframes slideDownCharacter {
    0% {
      top: -200px;
      opacity: 0;
    }
    100% {
      top: 214px;
      opacity: 1;
    }
  }

  .shadow {
    width: 189px;
    height: 35px;
    top: 547px;
    position: absolute;
    left: 102px;
    will-change: transform;
    pointer-events: none;
    animation: shadow 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    z-index: 3;
  }

  @keyframes shadow {
    0% {
      will-change: transform;
      transform: scale(0);
    }
    100% {
      will-change: transform;
      transform: scale(1);
    }
  }

  .startCharacter {
    width: 156.52px;
    height: 180px;
    position: absolute;
    top: 979px;
    left: 0px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: left, opacity;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .startCharacter.visible {
    left: 110px;
    opacity: 1;
  }

  .smashCharacter {
    width: 151.09px;
    height: 182.02px;
    position: absolute;
    left: 300px;
    top: 1179px;
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s, opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s;
  }

  .smashCharacter.visible {
    left: 120px;
    opacity: 1;
  }

  .earnCharacter {
    width: 148.13px;
    height: 180px;
    position: absolute;
    left: 0px;
    top: 1379px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: left, opacity;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s, opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .earnCharacter.visible {
    left: 130px;
    opacity: 1;
  }

  .smashToEarnCharacter {
    width: 195px;
    height: 101px;
    position: absolute;
    left: -104px;
    top: 2180px;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .smashToEarnCharacter.visible {
    left: 95px;
    opacity: 1;
  }

  .ffffToEarnCharacter {
    width: 195px;
    height: 121px;
    position: absolute;
    left: 100px;
    top: 2500px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .ffffToEarnCharacter.visible {
    top: 2335px;
    opacity: 1;
  }

  .playToEarnCharacter {
    width: 192px;
    height: 99px;
    position: absolute;
    left: 155px;
    top: 2728px;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .playToEarnCharacter.visible {
    left: 105px;
    opacity: 1;
  }
}
