/* Стили всех стрелок */
@media screen and (min-width: 1921px) {

  .arrowToSmashMobile,
  .arrowToEarnMobile,
  .arrowToFfffToEarnMobile,
  .arrowToPlayToEarnMobile {
    display: none;
  }

  .arrowToSmash {
    width: 280px;
    height: 280px;
    top: 1345px;
    left: 545px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    pointer-events: none;
  }

  .arrowToEarn {
    width: 330px;
    height: 300px;
    top: 1730px;
    left: 970px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    pointer-events: none;
  }

  .arrowToSmash, .arrowToEarn {
    opacity: 0;
    transform: scale(1);
    transition: opacity 0.5s ease 1s, transform 0.5s ease;
    pointer-events: none;
  }

  .arrowToSmash.visible, .arrowToEarn.visible {
    opacity: 1;
    transform: scale(1);
  }

  .arrowToFfffToEarn {
    width: 280px;
    height: 285px;
    top: 3830px;
    left: 960px;
    position: absolute;
    opacity: 0;
    transform: scale(1) rotate(65deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.5s ease 1s, transform 0.5s ease;
    pointer-events: none;
  }

  .arrowToFfffToEarn.visible {
    opacity: 1;
    transform: scale(1) rotate(65deg);
  }

  .arrowToPlayToEarn {
    width: 300px;
    height: 285px;
    top: 4340px;
    left: 540px;
    position: absolute;
    transform: scale(1) rotate(125deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.5s ease 1s, transform 0.5s ease;
    opacity: 0;
    pointer-events: none;
  }

  .arrowToPlayToEarn.visible {
    opacity: 1;
    transform: scale(1) rotate(125deg);
  }

  .arrowPlayButtonTopMiddle {
    width: 280px;
    height: 490px;
    top: 4900px;
    left: 875px;
    position: absolute;
    transform: translate(0%, 0px) rotate(-2deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .arrowPlayButtonTopLeft {
    width: 450px;
    height: 550px;
    top: 5060px;
    left: 220px;
    position: absolute;
    transform: translate(0%, 0px) rotate(180deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .arrowPlayButtonTopRight {
    width: 250px;
    height: 400px;
    top: 5100px;
    left: 1335px;
    position: absolute;
    transform: translate(0%, 0px) rotate(50deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .arrowPlayButtonDownLeft {
    width: 250px;
    height: 400px;
    top: 5550px;
    left: 300px;
    position: absolute;
    transform: translate(0%, 0px) rotate(230deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .arrowPlayButtonDownRight {
    width: 450px;
    height: 550px;
    top: 5450px;
    left: 1270px;
    position: absolute;
    transform: translate(0%, 0px);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }
}
