@media screen and (min-width: 394px) and (max-width: 480px) {

  .character {
    width: 239px;
    height: 386px;
    position: absolute;
    left: 95px;
    animation: slideDownCharacter 1.2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 5;
  }

  @keyframes slideDownCharacter {
    0% {
      top: -200px;
      opacity: 0;
    }
    100% {
      top: 244px;
      opacity: 1;
    }
  }

  .shadow {
    width: 189px;
    height: 35px;
    top: 610px;
    position: absolute;
    left: 120px;
    transform: scale(1);
    transform-origin: center;
    animation: shadow 1.2s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes shadow {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .startCharacter {
    width: 156.52px;
    height: 180px;
    position: absolute;
    top: 1010px;
    left: 0px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: left, opacity;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .startCharacter.visible {
    left: 130px;
    opacity: 1;
  }

  .smashCharacter {
    width: 151.09px;
    height: 182.02px;
    position: absolute;
    left: 300px;
    top: 1210px;
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s, opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s;
  }

  .smashCharacter.visible {
    left: 140px;
    opacity: 1;
  }

  .earnCharacter {
    width: 148.13px;
    height: 180px;
    position: absolute;
    left: 0px;
    top: 1410px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: left, opacity;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s, opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .earnCharacter.visible {
    left: 150px;
    opacity: 1;
  }

  .smashToEarnCharacter {
    width: 237px;
    height: 122.29px;
    position: absolute;
    left: -100px;
    top: 2250px;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .smashToEarnCharacter.visible {
    left: 95px;
    opacity: 1;
  }

  .ffffToEarnCharacter {
    width: 230px;
    height: 150px;
    position: absolute;
    left: 100px;
    top: 2550px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .ffffToEarnCharacter.visible {
    top: 2420px;
    opacity: 1;
  }

  .playToEarnCharacter {
    width: 226.43px;
    height: 116.75px;
    position: absolute;
    left: 110px;
    top: 2840px;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
  }

  .playToEarnCharacter.visible {
    left: 105px;
    opacity: 1;
  }
}
