@media screen and (min-width: 1441px) and (max-width: 1920px) {

  .divider {
    height: 53px;
    position: absolute;
    opacity: 0;
    left: 968px;
    transform: translate(0%, 0px);
    animation: slideDivider 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 1;
  }

  @keyframes slideDivider {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 84px;
      opacity: 1;
    }
  }

  .engLanguage {
    width: 60px;
    height: 37px;
    left: 892px;
    z-index: 1;
    position: absolute;
    will-change: transform, opacity;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: inline-block;
    animation: sliderLanguage 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .cnLanguage {
    width: 44px;
    height: 37px;
    z-index: 1;
    left: 990px;
    position: absolute;
    transition: transform 0.3s ease;
    will-change: transform, opacity;
    cursor: pointer;
    display: inline-block;
    animation: sliderLanguage 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .engLanguage:hover, .cnLanguage:hover {
    will-change: transform;
    transform: scale(1.11);
  }

  @keyframes sliderLanguage {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 90px;
      opacity: 1;
    }
  }

  .playButton {
    width: 341.68px;
    height: 150px;
    position: absolute;
    left: 937px;
    transform: translate(0%, 0px);
    animation: sliderPlayButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .playButton:hover {
    transform: scale(1.11);
  }

  @keyframes sliderPlayButton {
    0% {
      top: 800px;
      opacity: 0;
    }
    100% {
      top: 593px;
      opacity: 1;
    }
  }

  .playButtonFooter {
    width: 378.98px;
    height: 166.37px;
    position: absolute;
    left: 777px;
    top: 5420px;
    transform: scale(1);
    transform-origin: center;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease-out, opacity 0.3s ease;
    opacity: 0;
  }

  .playButtonFooter.visible {
    opacity: 1;
    transform: scale(1.61);
  }

  .playButtonFooter:hover {
    transform: scale(1.71);
  }

  .telegramChannelButton {
    width: 75.29px;
    height: 66.18px;
    position: absolute;
    left: 1302.86px;
    transform: translate(0%, 0px);
    animation: sliderTelegramChannelButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .telegramChannelButton:hover {
    transform: scale(1.11);
  }

  @keyframes sliderTelegramChannelButton {
    0% {
      top: 800px;
      opacity: 0;
    }
    100% {
      top: 593px;
      opacity: 1;
    }
  }

  .xAccountButton {
    width: 70.79px;
    height: 66.18px;
    position: absolute;
    left: 1306.28px;
    transform: translate(0%, 0px);
    animation: sliderXAccountButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .xAccountButton:hover {
    transform: scale(1.11);
  }

  @keyframes sliderXAccountButton {
    0% {
      top: 883.82px;
      opacity: 0;
    }
    100% {
      top: 676.82px;
      opacity: 1;
    }
  }

  .playVideoButton {
    width: 141.72px;
    height: 126.89px;
    position: absolute;
    top: 2900px;
    left: 885px;
    will-change: transform, opacity;
    z-index: 6;
    cursor: pointer;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1), transform 0.3s ease;
  }

  .playVideoButton:hover {
    transform: scale(1.11);
  }

  .playVideoButton.visible {
    top: 2615px;
    opacity: 1;
  }
}
