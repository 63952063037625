@media screen and (min-width: 481px) and (max-width: 1440px) {

  .p2eTextMobileEng,
  .p2eTextMobileCn,
  .roadMapQ3TextMobileEng,
  .roadMapQ3TextMobileCn,
  .roadMapQ1TextMobileCn,
  .roadMapQ1TextMobileEng,
  .roadMapQ4TextMobileCn,
  .roadMapQ4TextMobileEng {
    display: none;
  }

  .duriano-header {
    width: 670px;
    height: 107.83px;
    position: absolute;
    left: 400px;
    top: 107.33px;
    transform: translate(0%, 0px);
    animation: slideDurianoHeader 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 4;
  }

  @keyframes slideDurianoHeader {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 135.84px;
      opacity: 1;
    }
  }

  .p2eTextEng {
    width: 410.25px;
    height: 165.75px;
    position: absolute;
    top: 267.75px;
    left: 672px;
    animation: slideDownP2eTextEng 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes slideDownP2eTextEng {
    0% {
      top: 125.25px;
      opacity: 0;
    }
    100% {
      top: 267.75px;
      opacity: 1;
    }
  }

  .p2eTextCn {
    width: 374.25px;
    height: 165.75px;
    position: absolute;
    left: 672px;
    animation: slideDownP2eTextCn 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes slideDownP2eTextCn {
    0% {
      top: 125.25px;
      opacity: 0;
    }
    100% {
      top: 267.75px;
      opacity: 1;
    }
  }


  .howToHeaderEng {
    width: 375.75px;
    height: 110.25px;
    position: absolute;
    left: 532.5px;
    top: 975px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .howToHeaderEng.visible {
    top: 900px;
    opacity: 1;
  }


  .howToHeaderCn {
    width: 288px;
    height: 110.25px;
    position: absolute;
    left: 575.25px;
    top: 975px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .howToHeaderCn.visible {
    top: 900px;
    opacity: 1;
  }


  .trailerHeaderEng {
    width: 367.5px;
    height: 110.25px;
    position: absolute;
    top: 1725px;
    left: 536.25px;
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .trailerHeaderEng.visible {
    top: 1650px;
    opacity: 1;
  }

  .trailerHeaderCn {
    width: 288px;
    height: 110.25px;
    position: absolute;
    top: 1725px;
    left: 575.25px;
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .trailerHeaderCn.visible {
    top: 1650px;
    opacity: 1;
  }

  .roadmapHeaderEng {
    width: 450.75px;
    height: 110.25px;
    position: absolute;
    top: 2535px;
    left: 498.75px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 3;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .roadmapHeaderEng.visible {
    top: 2460px;
    opacity: 1;
  }

  .roadmapHeaderCn {
    width: 288px;
    height: 110.25px;
    position: absolute;
    top: 2535px;
    left: 575.25px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 3;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .roadmapHeaderCn.visible {
    top: 2460px;
    opacity: 1;
  }

  .q3Header {
    width: 143.5px;
    height: 119px;
    position: absolute;
    left: 320px;
    top: 2550px;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q3Header.visible {
    top: 2760px;
    opacity: 1;
  }

  .q4Header {
    width: 137.9px;
    height: 108.5px;
    position: absolute;
    left: 1000px;
    top: 3600px;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q4Header.visible {
    top: 3020px;
    opacity: 1;
  }

  .q1Header {
    width: 152.6px;
    height: 100.1px;
    position: absolute;
    left: 320px;
    top: 3910px;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q1Header.visible {
    top: 3380px;
    opacity: 1;
  }

  .roadMapQ3TextEng {
    width: 315px;
    height: 45px;
    position: absolute;
    left: 1290px;
    top: 2860px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ3TextEng.visible {
    left: 800px;
    opacity: 1;
  }
  
  .roadMapQ3TextCn {
    width: 315px;
    height: 45px;
    position: absolute;
    left: 1290px;
    top: 2860px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ3TextCn.visible {
    left: 800px;
    opacity: 1;
  }
  
  .roadMapQ4TextEng {
    width: 373.5px;
    height: 45px;
    position: absolute;
    left: 0px;
    top: 3150px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ4TextEng.visible {
    left: 270px;
    opacity: 1;
  }
  
  .roadMapQ4TextCn {
    width: 373.5px;
    height: 45px;
    position: absolute;
    left: 0px;
    top: 3150px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ4TextCn.visible {
    left: 250px;
    opacity: 1;
  }
  
  .roadMapQ1TextEng {
    width: 305.1px;
    height: 45px;
    position: absolute;
    left: 1000px;
    top: 3500px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ1TextEng.visible {
    left: 800px;
    opacity: 1;
  }
  
  .roadMapQ1TextCn {
    width: 305.1px;
    height: 45px;
    position: absolute;
    left: 1000px;
    top: 3500px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ1TextCn.visible {
    left: 800px;
    opacity: 1;
  }  
}
