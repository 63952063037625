@media screen and (min-width: 481px) and (max-width: 1440px) {

  .divider {
    height: 40px;
    position: absolute;
    opacity: 0;
    left: 726px;
    transform: translate(0%, 0px);
    animation: slideDivider 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 1;
  }

  @keyframes slideDivider {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 60px;
      opacity: 1;
    }
  }

  .engLanguage {
    width: 45px;
    height: 28px;
    left: 669px;
    z-index: 1;
    position: absolute;
    will-change: transform, opacity;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: inline-block;
    animation: sliderLanguage 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .cnLanguage {
    width: 33px;
    height: 28px;
    z-index: 1;
    left: 743px;
    position: absolute;
    transition: transform 0.3s ease;
    will-change: transform, opacity;
    cursor: pointer;
    display: inline-block;
    animation: sliderLanguage 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .engLanguage:hover, .cnLanguage:hover {
    will-change: transform;
    transform: scale(1.11);
  }

  @keyframes sliderLanguage {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 67px;
      opacity: 1;
    }
  }

  .playButton {
    width: 256.26px;
    height: 112.5px;
    position: absolute;
    left: 702px;
    top: 443px;
    transform: translate(0%, 0px);
    animation: sliderPlayButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
    opacity: 0;
  }

  .playButton:hover {
    transform: scale(1.11);
  }

  @keyframes sliderPlayButton {
    0% {
      top: 650px;
      opacity: 0;
    }
    100% {
      top: 481px;
      opacity: 1;
    }
  }

  .playButtonFooter {
    width: 284.24px;
    height: 124.96px;
    position: absolute;
    left: 583px;
    top: 4065px;
    transform: scale(1);
    transform-origin: center;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease-out, opacity 0.3s ease;
    opacity: 0;
  }

  .playButtonFooter.visible {
    opacity: 1;
    transform: scale(1.61);
  }

  .playButtonFooter:hover {
    transform: scale(1.71);
  }

  .telegramChannelButton {
    width: 56.47px;
    height: 49.63px;
    position: absolute;
    left: 976.43px;
    transform: translate(0%, 0px);
    animation: sliderTelegramChannelButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .telegramChannelButton:hover {
    transform: scale(1.11);
  }

  @keyframes sliderTelegramChannelButton {
    0% {
      top: 645px;
      opacity: 0;
    }
    100% {
      top: 476px;
      opacity: 1;
    }
  }

  .xAccountButton {
    width: 53.09px;
    height: 49.63px;
    position: absolute;
    left: 979.71px;
    transform: translate(0%, 0px);
    animation: sliderXAccountButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .xAccountButton:hover {
    transform: scale(1.11);
  }

  @keyframes sliderXAccountButton {
    0% {
      top: 697.86px;
      opacity: 0;
    }
    100% {
      top: 535.61px;
      opacity: 1;
    }
  }

  .playVideoButton {
    width: 106.29px;
    height: 95.92px;
    position: absolute;
    top: 2175px;
    left: 665px;
    will-change: transform, opacity;
    z-index: 6;
    cursor: pointer;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1), transform 0.3s ease;
  }

  .playVideoButton:hover {
    transform: scale(1.11);
  }

  .playVideoButton.visible {
    top: 1961px;
    opacity: 1;
  }
}
