@media screen and (max-width: 375px) {

  .arrowToSmash,
  .arrowToEarn,
  .arrowToFfffToEarn,
  .arrowToPlayToEarn {
    display: none;
  }

  .arrowToSmashMobile {
    width: 110px;
    height: 250px;
    top: 1030px;
    left: 50px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    pointer-events: none;
  }

  .arrowToEarnMobile {
    width: 110px;
    height: 250px;
    top: 1260px;
    left: 230px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    pointer-events: none;
  }

  .arrowToSmashMobile, .arrowToEarnMobile {
    opacity: 0;
    transform: scale(1);
    transition: opacity 0.5s ease 1s, transform 0.5s ease;
    pointer-events: none;
  }

  .arrowToSmashMobile.visible, .arrowToEarnMobile.visible {
    opacity: 1;
    transform: scale(1);
  }

  .arrowToFfffToEarnMobile {
    width: 110px;
    height: 280px;
    top: 2130px;
    left: 45px;
    position: absolute;
    opacity: 0;
    transform: scale(1) rotate(65deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.5s ease 1s, transform 0.5s ease;
    pointer-events: none;
  }

  .arrowToFfffToEarnMobile.visible {
    opacity: 1;
    transform: scale(1);
  }

  .arrowToPlayToEarnMobile {
    width: 110px;
    height: 280px;
    top: 2400px;
    left: 220px;
    position: absolute;
    transform: scale(1) rotate(105deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.5s ease 1s, transform 0.5s ease;
    opacity: 0;
    pointer-events: none;
  }

  .arrowToPlayToEarnMobile.visible {
    opacity: 1;
    transform: scale(1);
  }

  .arrowPlayButtonTopMiddle {
    width: 90px;
    height: 170px; 
    top: 2890px;
    left: 170px;
    position: absolute;
    transform: translate(0%, 0px) rotate(-2deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .arrowPlayButtonTopLeft {
    width: 140px;
    height: 250px; 
    top: 2900px;
    left: -30px;
    position: absolute;
    transform: translate(0%, 0px) rotate(180deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .arrowPlayButtonTopRight {
    width: 90px;
    height: 150px; 
    top: 2935px;
    left: 305px;
    position: absolute;
    transform: translate(0%, 0px) rotate(50deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .arrowPlayButtonDownLeft {
    width: 90px;
    height: 170px;
    top: 3090px;
    left: -10px;
    position: absolute;
    transform: translate(0%, 0px) rotate(230deg);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }

  .arrowPlayButtonDownRight {
    width: 137.34px;
    height: 242.6px; 
    top: 3040px;
    left: 280px;
    position: absolute;
    transform: translate(0%, 0px);
    will-change: transform, opacity;
    z-index: 2;
    cursor: pointer;
    transition: transform 0.3s ease;
    pointer-events: none;
  }
}
