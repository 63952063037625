@media screen and (min-width: 376px) and (max-width: 393px) {

  .cloud_left,
  .cloud_right,
  .bamboo_small_left,
  .bamboo_small_right,
  .bamboo_big_left_1,
  .bamboo_big_right_1,
  .bamboo_big_left_2,
  .bamboo_big_right_2,
  .bridge-background,
  .textureLeft,
  .textureMiddle,
  .textureRight,
  .bridge-backgroundUltraWide,
  .textureRightUltra,
  .textureLeftUltra,
  .bamboo_big_left_1_ultra,
  .bamboo_big_right_1_ultra,
  .footer {
      display: none;
  }

  .background {
    width: 393px;
    height: 3357px;
    position: relative;
    overflow: hidden;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #496d20;
    border: 15px solid #496d20;
    border-radius: 10px;
    width: 278px;
    height: 159.7px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform, opacity;
  }

  .modal-content.open {
    animation: fadeIn 0.5s ease forwards;
  }

  @keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    top: -45px;
    right: -45px;
    color: #496d20;
    font-size: 56px;
    font-weight: 300;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: #273b10;
    text-decoration: none;
  }

  .backgroundBlue {
    width: 393px;
    height: 582px;
    top: 0px;
    background-color: #3FD0FF;
    position: absolute;
    justify-content: center;
    left: 0;
    z-index: 1;
  }

  .backgroundGreen {
    width: 393px;
    height: 2775px;
    top: 582px;
    position: absolute;
    left: 0;
    background-color: #68A025;
    justify-content: center;
    z-index: 1;
  }

  .cloud_left_mobile {
    width: 570.5px;
    height: 261px;
    top: 157.5px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: left, opacity;
    animation: slideCloudLeftMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudLeftMobile {
    0% {
      left: -637.5px;
      opacity: 0;
    }
    100% {
      left: -437.5px;
      opacity: 1;
    }
  }

  .cloud_right_mobile {
    width: 672.08px;
    height: 350.61px;
    top: 108px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: left, opacity;
    animation: slideCloudRightMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudRightMobile {
    0% {
      left: 468px;
      opacity: 0;
    }
    100% {
      left: 268px;
      opacity: 1;
    }
  }

  .cloud_top_mobile {
    width: 586px;
    height: 255.5px;
    left: -173px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: top, opacity;
    animation: slideCloudTopMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudTopMobile {
    0% {
      top: -270px;
      opacity: 0;
    }
    100% {
      top: -170px;
      opacity: 1;
    }
  }

  .bamboo_small_left_mobile {
    width: 237.32px;
    height: 469.73px;
    top: 100px;
    z-index: 2;
    will-change: left;
    position: absolute;
    pointer-events: none;
    animation: slideBambooSmallLeftMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooSmallLeftMobile {
    0% {
      left: -219px;
    }
    100% {
      left: -119px;
    }
  }
  
  .bamboo_small_right_mobile {
    width: 125.71px;
    height: 372.6px;
    position: absolute;
    top: 189.86px;
    z-index: 2;
    will-change: left;
    pointer-events: none;
    animation: slideBambooSmallRightMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooSmallRightMobile {
    0% {
      left: 402px;
      opacity: 0;
    }
    100% {
      left: 302px;
      opacity: 1;
    }
  }

  .bamboo_big_left_mobile {
    width: 278.75px;
    height: 2978.89px;
    top: 429px;
    z-index: 2;
    will-change: transform;
    position: absolute;
    pointer-events: none;
    animation: slideBambooBigLeftMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooBigLeftMobile {
    0% {
      left: -230px;
    }
    100% {
      left: -130px;
    }
  }

  .bamboo_big_right_mobile {
    width: 278.75px;
    height: 2979px;
    top: 480px;
    z-index: 2;
    will-change: transform;
    position: absolute;
    pointer-events: none;
    animation: slideBambooBigRightMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooBigRightMobile {
    0% {
      left: 342px;
    }
    100% {
      left: 242px;
    }
  }

  .bridge-background-mobile {
    width: 393px;
    height: 112px;
    top: 501px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
  }

  .textureMiddleMobile {
    width: 393px;
    height: 2775px;
    top: 582px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
  }

  .windowTrailerVideo {
    width: 278px;
    height: 159.7px;
    left: 55px;
    top: 1817.09px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
    transform-origin: center;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .windowTrailerVideo.visible {
    top: 1717.09px;
    opacity: 1;
  }

  .trailerVideo {
    width: 278px;
    height: 159.7px;
    left: 55px;
    top: 1817.09px;
    z-index: 5;
    position: absolute;
    transform-origin: center;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .trailerVideo.visible {
    top: 1717.09px;
    opacity: 1;
  }

  .footer-mobile {
    width: 400px;
    height: 153px;
    position: absolute;
    left: 0px;
    z-index: 2;
    will-change: top;
    pointer-events: none;
    opacity: 0;
  }

  .footer-mobile.visible {
    animation: footer-mobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 1;
  }

  @keyframes footer-mobile {
    0% {
      top: 3404px;
    }
    100% {
      top: 3204px;
    }
  }
}
