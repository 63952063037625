@media screen and (min-width: 394px) and (max-width: 480px) {

  .p2eTextEng,
  .p2eTextCn,
  .roadMapQ3TextEng,
  .roadMapQ3TextCn,
  .roadMapQ4TextEng,
  .roadMapQ4TextCn,
  .roadMapQ1TextEng,
  .roadMapQ1TextCn {
    display: none;
  }

  .duriano-header {
    width: 325px;
    height: 52px;
    position: absolute;
    left: 52px;
    transform: translate(0%, 0px);
    animation: slideDurianoHeader 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 4;
  }

  @keyframes slideDurianoHeader {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 124px;
      opacity: 1;
    }
  }

  .p2eTextMobileEng {
    width: 262px;
    height: 28px;
    position: absolute;
    left: 83px;
    animation: slideDownP2eTextMobileEng 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes slideDownP2eTextMobileEng {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 184px;
      opacity: 1;
    }
  }

  .p2eTextMobileCn {
    width: 216px;
    height: 28px;
    position: absolute;
    left: 100px;
    animation: slideDownP2eTextMobileCn 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes slideDownP2eTextMobileCn {
    0% {
      top: 85px;
      opacity: 0;
    }
    100% {
      top: 185px;
      opacity: 1;
    }
  }

  .howToHeaderEng {
    width: 188px;
    height: 55px;
    position: absolute;
    left: 120px;
    top: 1010px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .howToHeaderEng.visible {
    top: 910px;
    opacity: 1;
  }

  .howToHeaderCn {
    width: 188px;
    height: 55px;
    position: absolute;
    left: 120px;
    top: 1000px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .howToHeaderCn.visible {
    top: 900px;
    opacity: 1;
  }

  .trailerHeaderEng {
    width: 184px;
    height: 55px;
    position: absolute;
    top: 1760px;
    left: 122px;
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .trailerHeaderEng.visible {
    top: 1660px;
    opacity: 1;
  }

  .trailerHeaderCn {
    width: 184px;
    height: 55px;
    position: absolute;
    top: 1760px;
    left: 122px;
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .trailerHeaderCn.visible {
    top: 1660px;
    opacity: 1;
  }

  .roadmapHeaderEng {
    width: 226px;
    height: 55px;
    position: absolute;
    top: 2111px;
    left: 105px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 3;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .roadmapHeaderEng.visible {
    top: 2011px;
    opacity: 1;
  }

  .roadmapHeaderCn {
    width: 226px;
    height: 55px;
    position: absolute;
    top: 2111px;
    left: 105px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 3;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .roadmapHeaderCn.visible {
    top: 2011px;
    opacity: 1;
  }

  .q3Header {
    width: 85px;
    height: 72px;
    position: absolute;
    left: 90px;
    top: 2554px;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q3Header.visible {
    top: 2225px;
    opacity: 1;
  }

  .q4Header {
    width: 85px;
    height: 72px;
    position: absolute;
    left: 260px;
    top: 2861px;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q4Header.visible {
    top: 2512px;
    opacity: 1;
  }

  .q1Header {
    width: 88px;
    height: 58px;
    position: absolute;
    left: 103px;
    top: 3140px;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q1Header.visible {
    top: 2810px;
    opacity: 1;
  }

  .roadMapQ3TextMobileEng {
    width: 146px;
    height: 79px;
    position: absolute;
    left: 340px;
    top: 2305px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ3TextMobileEng.visible {
    left: 140px;
    opacity: 1;
  }

  .roadMapQ3TextMobileCn {
    width: 146px;
    height: 79px;
    position: absolute;
    left: 340px;
    top: 2294px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ3TextMobileCn.visible {
    left: 115px;
    opacity: 1;
  }

  .roadMapQ4TextMobileEng {
    width: 118px;
    height: 74px;  
    position: absolute;
    left: 0px;
    top: 2601px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ4TextMobileEng.visible {
    left: 158px;
    opacity: 1;
  }

  .roadMapQ4TextMobileCn {
    width: 118px;
    height: 74px;  
    position: absolute;
    left: 0px;
    top: 2601px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ4TextMobileCn.visible {
    left: 158px;
    opacity: 1;
  }

  .roadMapQ1TextMobileEng {
    width: 129;
    height: 74px;
    position: absolute;
    top: 2894px;
    left: 0px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ1TextMobileEng.visible {
    left: 153px;
    opacity: 1;
  }

  .roadMapQ1TextMobileCn {
    width: calc(round(320px * 1.1));
    height: calc(round(1538px * 1.1)); 
    position: absolute;
    left: 1810px;
    top: 2894px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ1TextMobileCn.visible {
    opacity: 1;
    left: 82px;
  }
}
