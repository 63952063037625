/* Стили фона и фоновых блоков для компьютера */
@media screen and (min-width: 481px) and (max-width: 1440px) {

  .cloud_left_mobile,
  .cloud_right_mobile,
  .cloud_top_mobile,
  .bamboo_small_left_mobile,
  .bamboo_small_right_mobile,
  .bridge-background-mobile,
  .textureMiddleMobile,
  .bamboo_big_left_mobile,
  .bamboo_big_right_mobile,
  .bridge-backgroundUltraWide,
  .textureRightUltra,
  .textureLeftUltra,
  .bamboo_big_left_1_ultra,
  .bamboo_big_right_1_ultra,
  .footer-mobile {
    display: none;
  }

  .background {
    width: 1440px;
    height: 4749.75px;
    overflow: hidden;
    position: relative;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #496d20;
    border: 15px solid #496d20;
    border-radius: 10px;
    width: 775.5px;
    height: 436px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform, opacity;
  }

  .modal-content.open {
    animation: fadeIn 0.5s ease forwards;
  }

  @keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    top: -60px;
    right: -60px;
    color: #496d20;
    font-size: 56px;
    font-weight: 300;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #273b10;
    text-decoration: none;
  }

  .backgroundBlue {
    width: 1440px;
    height: 735px;
    top: 0px;
    background-color: #3FD0FF;
    position: absolute;
    justify-content: center;
    left: 0;
    z-index: 1;
  }

  .backgroundGreen {
    width: 1440px;
    height: 4014.75px;
    top: 735px;
    position: absolute;
    left: 0;
    background-color: #68A025;
    justify-content: center;
    z-index: 1;
  }

  .cloud_left {
    width: 712.5px;
    height: 457.125px;
    top: 54px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: left, opacity;
    animation: slideCloudLeft 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudLeft {
    0% {
      left: 150px;
      opacity: 0;
    }
    100% {
      left: -380.25px;
      opacity: 1;
    }
  }

  .cloud_right {
    width: 709.185px;
    height: 461.25px;
    top: -10.5px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: left, opacity;
    animation: slideCloudRight 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudRight {
    0% {
      left: 525px;
      opacity: 0;
    }
    100% {
      left: 1146.375px;
      opacity: 1;
    }
  }

  .bamboo_small_left {
    width: 211.425px;
    height: 812.8425px;
    top: 0px;
    left: 0px;
    z-index: 3;
    will-change: left;
    position: absolute;
    pointer-events: none;
    animation: slideBambooSmallLeft 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooSmallLeft {
    0% {
      left: -225px;
    }
    100% {
      left: 0px;
    }
  }

  .bamboo_small_right {
    width: 213.0075px;
    height: 811.38px;
    position: absolute;
    top: 11.2125px;
    left: 1230px;
    z-index: 4;
    will-change: left;
    pointer-events: none;
    animation: slideBambooSmallRight 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooSmallRight {
    0% {
      left: 1462.5px;
      opacity: 0;
    }
    100% {
      left: 1230px;
      opacity: 1;
    }
  }

  .bridge-background {
    width: 1440px;
    height: 197.625px;
    top: 689.25px;
    z-index: 3;
    position: absolute;
    pointer-events: none;
  }

  .textureLeft {
    width: 300px;
    height: 3918.75px;
    left: -7.5px;
    top: 765px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
  }

  .textureMiddle {
    width: 1423.455px;
    height: 4050.6375px;
    left: 8.25px;
    top: 750px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
  }

  .textureRight {
    width: 300px;
    height: 3918.75px;
    left: 1162.5px;
    top: 765px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
  }

  .bamboo_big_left_1 {
    width: 367.5px;
    height: 2250.3825px;
    top: -22.5px;
    z-index: 3;
    will-change: left;
    position: absolute;
    pointer-events: none;
    animation: slideBambooBigLeft1 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooBigLeft1 {
    0% {
      left: -375px;
    }
    100% {
      left: -22.5px;
    }
  }

  .bamboo_big_right_1 {
    width: 367.5px;
    height: 2250.3825px;
    top: -22.5px;
    z-index: 4;
    will-change: left;
    position: absolute;
    pointer-events: none;
    animation: slideBambooBigRight1 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooBigRight1 {
    0% {
      left: 1500px;
    }
    100% {
      left: 1102.5px;
    }
  }

  .bamboo_big_left_2 {
    width: 1324.0425px;
    height: 4892.07px;
    top: 637.5px;
    left: -645px;
    z-index: 4;
    transform: rotate(1deg);
    position: absolute;
    pointer-events: none;
    transform-origin: center;
  }

  .bamboo_big_right_2 {
    width: 1275.57px;
    height: 4892.07px;
    left: 675px;
    top: 600px;
    z-index: 3;
    transform: rotate(0deg);
    position: absolute;
    pointer-events: none;
    transform-origin: center;
  }

  .windowTrailerVideo {
    width: 775.5px;
    height: 445.5px;
    left: 332.25px;
    top: 2000.25px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
    transform-origin: center;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .windowTrailerVideo.visible {
    top: 1786.5px;
    opacity: 1;
  }

  .footer {
    width: 1440px;
    height: 537.5925px;
    position: absolute;
    top: 4749.75px;
    z-index: 2;
    will-change: top, left;
    pointer-events: none;
    opacity: 0;
  }

  .footer.visible {
    animation: footer 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 1;
  }

  @keyframes footer {
    0% {
      top: 4749.75px;
    }
    100% {
      top: 4322.25px;
    }
  }
}
