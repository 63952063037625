/* styles/main.css */
@import '2560px/background-2560.css';
@import '1920px/background-1920.css';
@import '1440px/background-1440.css';
@import '430px/background-430.css';
@import '393px/background-393.css';
@import '375px/background-375.css';

@import '2560px/characters-2560.css';
@import '1920px/characters-1920.css';
@import '1440px/characters-1440.css';
@import '430px/characters-430.css';
@import '393px/characters-393.css';
@import '375px/characters-375.css';

@import '2560px/text-2560.css';
@import '1920px/text-1920.css';
@import '1440px/text-1440.css';
@import '430px/text-430.css';
@import '393px/text-393.css';
@import '375px/text-375.css';

@import '2560px/buttons-2560.css';
@import '1920px/buttons-1920.css';
@import '1440px/buttons-1440.css';
@import '430px/buttons-430.css';
@import '393px/buttons-393.css';
@import '375px/buttons-375.css';

@import '2560px/arrows-2560.css';
@import '1920px/arrows-1920.css';
@import '1440px/arrows-1440.css';
@import '430px/arrows-430.css';
@import '393px/arrows-393.css';
@import '375px/arrows-375.css';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  position: relative;
  transform: translateY(-18px);
}

.hidden {
  display: none;
}

::selection {
  background-color: #68A025;
  color: #ffffff;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #324C0B #68A025;
}

.smashToEarnCharacter,
.ffffToEarn,
.playToEarnCharacter,
.q3Header,
.q4Header,
.q1Header,
.roadMapQ3TextEng,
.roadMapQ3TextCn,
.roadMapQ4TextEng,
.roadMapQ4TextCn,
.roadMapQ1TextEng,
.roadMapQ1TextCn,
.arrowToFfffToEarn,
.arrowToPlayToEarn {
  margin-top: -100px;
}

.cloud-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
