/* Стили текстовых блоков */
@media screen and (min-width: 1921px) {

  .p2eTextMobileEng,
  .p2eTextMobileCn,
  .roadMapQ3TextMobileEng,
  .roadMapQ3TextMobileCn,
  .roadMapQ1TextMobileCn,
  .roadMapQ1TextMobileEng,
  .roadMapQ4TextMobileCn,
  .roadMapQ4TextMobileEng {
    display: none;
  }

  .duriano-header {
    width: 893px;
    height: 143.77px;
    position: absolute;
    left: 550px;
    transform: translate(0%, 0px);
    animation: slideDurianoHeader 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 4;
  }
  
  @keyframes slideDurianoHeader {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 181.12px;
      opacity: 1;
    }
  }

  .p2eTextEng {
    width: 547px;
    height: 221px;
    position: absolute;
    top: 357px;
    left: 896px;
    animation: slideDownP2eTextEng 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  @keyframes slideDownP2eTextEng {
    0% {
      top: 157px;
      opacity: 0;
    }
    100% {
      top: 357px;
      opacity: 1;
    }
  }

  .p2eTextCn {
    width: 499px;
    height: 221px;
    position: absolute;
    left: 896px;
    animation: slideDownP2eTextCn 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes slideDownP2eTextCn {
    0% {
      top: 157px;
      opacity: 0;
    }
    100% {
      top: 357px;
      opacity: 1;
    }
  }

  .howToHeaderEng {
    width: 501px;
    height: 147px;
    position: absolute;
    left: 710px;
    top: 1300px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .howToHeaderEng.visible {
    top: 1200px;
    opacity: 1;
  }
  
  .howToHeaderCn {
    width: 384px;
    height: 147px;
    position: absolute;
    left: 767px;
    top: 1300px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .howToHeaderCn.visible {
    top: 1200px;
    opacity: 1;
  }
  
  .trailerHeaderEng {
    width: 490px;
    height: 147px;
    position: absolute;
    top: 2300px;
    left: 715px;
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .trailerHeaderEng.visible {
    top: 2200px;
    opacity: 1;
  }
  
  .trailerHeaderCn {
    width: 384px;
    height: 147px;
    position: absolute;
    top: 2300px;
    left: 767px;
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .trailerHeaderCn.visible {
    top: 2200px;
    opacity: 1;
  }
  
  .roadmapHeaderEng {
    width: 601px;
    height: 147px;
    position: absolute;
    top: 3380px;
    left: 665px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 3;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .roadmapHeaderEng.visible {
    top: 3280px;
    opacity: 1;
  }
  
  .roadmapHeaderCn {
    width: 384px;
    height: 147px;
    position: absolute;
    top: 3380px;
    left: 767px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 3;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  
  .roadmapHeaderCn.visible {
    top: 3280px;
    opacity: 1;
  }
  
  .q3Header {
    width: 205px;
    height: 170px;
    position: absolute;
    left: 439px;
    top: 3300px;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .q3Header.visible {
    top: 3600px;
    opacity: 1;
  }
  
  .q4Header {
    width: 197px;
    height: 155px;
    position: absolute;
    left: 1276px;
    top: 3975px;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .q4Header.visible {
    top: 4075px;
    opacity: 1;
  }
  
  .q1Header {
    width: 218px;
    height: 143px;
    position: absolute;
    left: 443px;
    top: 5088px;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .q1Header.visible {
    top: 4610px;
    opacity: 1;
  }
  
  .roadMapQ3TextEng {
    width: 350px;
    height: 50px;
    position: absolute;
    left: 1850px;
    top: 3750px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ3TextEng.visible {
    left: 1080px;
    opacity: 1;
  }

  .roadMapQ3TextCn {
    width: 350px;
    height: 50px;
    position: absolute;
    left: 1850px;
    top: 3750px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ3TextCn.visible {
    left: 1080px;
    opacity: 1;
  }

  .roadMapQ4TextEng {
    width: 415px;
    height: 50px;
    position: absolute;
    left: -100px;
    top: 4240px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ4TextEng.visible {
    left: 400px;
    opacity: 1;
  }

  .roadMapQ4TextCn {
    width: 415px;
    height: 50px;
    position: absolute;
    left: -100px;
    top: 4240px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ4TextCn.visible {
    left: 400px;
    opacity: 1;
  }

  .roadMapQ1TextEng {
    width: 339px;
    height: 50px;
    position: absolute;
    top: 4770px;
    left: 2080px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ1TextEng.visible {
    left: 1080px;
    opacity: 1;
  }

  .roadMapQ1TextCn {
    width: 339px;
    height: 50px;
    position: absolute;
    top: 4770px;
    left: 2080px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }
  
  .roadMapQ1TextCn.visible {
    left: 1080px;
    opacity: 1;
  }
}
