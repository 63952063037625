@media screen and (min-width: 376px) and (max-width: 393px) {

  .engLanguage {
    width: 30px;
    height: 18px;
    left: 154px;
    z-index: 1;
    position: absolute;
    will-change: transform, opacity;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: inline-block;
    animation: sliderLanguage 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .divider {
    height: 20px;
    position: absolute;
    opacity: 0;
    left: 200px;
    transform: translate(0%, 0px);
    animation: slideDivider 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 1;
  }
  
  @keyframes slideDivider {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 74px;
      opacity: 1;
    }
  }

  .cnLanguage {
    width: 22px;
    height: 18px;
    z-index: 1;
    left: 216px;
    position: absolute;
    transition: transform 0.3s ease;
    will-change: transform, opacity;
    cursor: pointer;
    display: inline-block;
    animation: sliderLanguage 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .engLanguage:hover, .cnLanguage:hover {
    transform: scale(1.1);
  }

  @keyframes sliderLanguage {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 75px;
      opacity: 1;
    }
  }

  .playButton {
    width: 206.5px;
    height: 90.65px;
    position: absolute;
    left: 63.22px;
    transform: translate(0%, 0px);
    animation: sliderPlayButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  @keyframes sliderPlayButton {
    0% {
      top: 877.22px;
      opacity: 0;
    }
    100% {
      top: 677.22px;
      opacity: 1;
    }
  }

  .telegramChannelButton {
    width: 45.5px;
    height: 39.99px;
    position: absolute;
    left: 283.5px;
    transform: translate(0%, 0px);
    animation: sliderTelegramChannelButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  @keyframes sliderTelegramChannelButton {
    0% {
      top: 877px;
      opacity: 0;
    }
    100% {
      top: 677px;
      opacity: 1;
    }
  }

  .xAccountButton {
    width: 42.78px;
    height: 39.99px;
    position: absolute;
    left: 286.16px;
    transform: translate(0%, 0px);
    animation: sliderXAccountButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  @keyframes sliderXAccountButton {
    0% {
      top: 927.66px;
      opacity: 0;
    }
    100% {
      top: 727.66px;
      opacity: 1;
    }
  }

  .playVideoButton {
    width: 89.35px;
    height: 80px;
    position: absolute;
    top: 1855px;
    left: 149px;
    will-change: transform, opacity;
    z-index: 6;
    cursor: pointer;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1), transform 0.3s ease;
  }

  .playVideoButton:hover {
    transform: scale(1.1);
  }

  .playVideoButton.visible {
    top: 1755px;
    opacity: 1;
  }

  .playButtonFooter {
    width: 123.175px;
    height: 54.075px;
    position: absolute;
    left: 135px;
    top: 3066.55px;
    transform: scale(1);
    transform-origin: center;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease-out, opacity 0.3s ease;
    opacity: 0;
  }

  .playButtonFooter.visible {
    opacity: 1;
    transform: scale(1.6);
  }
}
