@media screen and (min-width: 394px) and (max-width: 480px) {

  .divider {
    height: 20px;
    position: absolute;
    opacity: 0;
    left: 219px;
    transform: translate(0%, 0px);
    animation: slideDivider 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 1;
  }

  @keyframes slideDivider {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 80px;
      opacity: 1;
    }
  }

  .engLanguage {
    width: 30px;
    height: 18px;
    left: 173px;
    z-index: 1;
    position: absolute;
    will-change: transform, opacity;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: inline-block;
    animation: sliderLanguage 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .cnLanguage {
    width: 22px;
    height: 18px;
    z-index: 1;
    left: 235px;
    position: absolute;
    transition: transform 0.3s ease;
    will-change: transform, opacity;
    cursor: pointer;
    display: inline-block;
    animation: sliderLanguage 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .engLanguage:hover, .cnLanguage:hover {
    transform: scale(1.1);
  }

  @keyframes sliderLanguage {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 80px;
      opacity: 1;
    }
  }

  .playButton {
    width: 225px;
    height: 100px;
    position: absolute;
    left: 69px;
    transform: translate(0%, 0px);
    animation: sliderPlayButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  @keyframes sliderPlayButton {
    0% {
      top: 840px;
      opacity: 0;
    }
    100% {
      top: 740px;
      opacity: 1;
    }
  }

  .telegramChannelButton {
    width: 50px;
    height: 44px;
    position: absolute;
    left: 310.2px;
    transform: translate(0%, 0px);
    animation: sliderTelegramChannelButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  @keyframes sliderTelegramChannelButton {
    0% {
      top: 840px;
      opacity: 0;
    }
    100% {
      top: 740px;
      opacity: 1;
    }
  }

  .xAccountButton {
    width: 46.81px;
    height: 43.76px;
    position: absolute;
    left: 313px;
    top: 896px;
    animation: sliderXAccountButton 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: top, opacity;
    z-index: 4;
    cursor: pointer;
    transition: top 0.3s ease;
  }

  @keyframes sliderXAccountButton {
    0% {
      top: 896px;
      opacity: 0;
    }
    100% {
      top: 796px;
      opacity: 1;
    }
  }

  .playVideoButton {
    width: 89.35px;
    height: 80px;
    position: absolute;
    top: 1893px;
    left: 163px;
    will-change: transform, opacity;
    z-index: 6;
    cursor: pointer;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1), transform 0.3s ease;
  }

  .playVideoButton:hover {
    transform: scale(1.1);
  }

  .playVideoButton.visible {
    top: 1793px;
    opacity: 1;
  }

  .playButtonFooter {
    width: 134.375px;
    height: 59.375px;
    position: absolute;
    left: 145px;
    top: 3163px;
    transform: scale(1);
    transform-origin: center;
    will-change: transform, opacity;
    z-index: 4;
    cursor: pointer;
    transition: transform 0.3s ease-out, opacity 0.3s ease;
    opacity: 0;
  }

  .playButtonFooter.visible {
    opacity: 1;
    transform: scale(1.6);
  }
}
