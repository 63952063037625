/* Стили персонажей и их анимаций */
@media screen and (min-width: 481px) and (max-width: 1440px) {

  .character {
    width: 326.25px;
    height: 527.25px;
    left: 345px;
    animation: slideDownCharacter 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 5;
    position: absolute;
  }

  @keyframes slideDownCharacter {
    0% {
      top: -150px;
      opacity: 0;
    }
    100% {
      top: 237px;
      opacity: 1;
    }
  }

  .shadow {
    width: 264px;
    height: 48.75px;
    top: 738.75px;
    left: 387.75px;
    will-change: transform;
    pointer-events: none;
    animation: shadow 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    z-index: 4;
    position: absolute;
  }

  @keyframes shadow {
    0% {
      will-change: transform;
      transform: scale(0);
    }
    100% {
      will-change: transform;
      transform: scale(1);
    }
  }

  .startCharacter {
    width: 241.15px;
    height: 277.32px;
    top: 1440px;
    left: 332.25px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    position: absolute;
  }

  .startCharacter.visible {
    top: 1140px;
    opacity: 1;
  }

  .smashCharacter {
    width: 222.53px;
    height: 265.1px;
    top: 1383.75px;
    left: 611.25px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s, opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s;
    position: absolute;
  }

  .smashCharacter.visible {
    top: 1083.75px;
    opacity: 1;
  }

  .earnCharacter {
    width: 237.25px;
    height: 282.52px;
    top: 1440px;
    left: 877.5px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s, opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
    position: absolute;
  }

  .earnCharacter.visible {
    top: 1140px;
    opacity: 1;
  }

  .smashToEarnCharacter {
    width: 490px;
    height: 240px;
    top: 2790px;
    left: -100px;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
    position: absolute;
  }

  .smashToEarnCharacter.visible {
    left: 300px;
    opacity: 1;
  }

  .ffffToEarnCharacter {
    width: 510px;
    height: 290px;
    top: 3200px;
    left: 660px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
    position: absolute;
  }

  .ffffToEarnCharacter.visible {
    top: 2920px;
    opacity: 1;
  }

  .playToEarnCharacter {
    width: 490px;
    height: 240px;
    top: 3420px;
    left: -100px;
    opacity: 0;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 2;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
    position: absolute;
  }

  .playToEarnCharacter.visible {
    left: 300px;
    opacity: 1;
  }
}
