@media screen and (min-width: 394px) and (max-width: 480px) {

  .cloud_left,
  .cloud_right,
  .bamboo_small_left,
  .bamboo_small_right,
  .bamboo_big_left_1,
  .bamboo_big_right_1,
  .bamboo_big_left_2,
  .bamboo_big_right_2,
  .bridge-background,
  .textureLeft,
  .textureMiddle,
  .textureRight,
  .bridge-backgroundUltraWide,
  .textureRightUltra,
  .textureLeftUltra,
  .bamboo_big_left_1_ultra,
  .bamboo_big_right_1_ultra,
  .footer {
      display: none;
  }

  .background {
    width: 480px;
    height: 3473px;
    position: relative;
    overflow: hidden;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #496d20;
    border: 15px solid #496d20;
    border-radius: 10px;
    width: 278px;
    height: 159.7px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform, opacity;
  }

  .modal-content.open {
    animation: fadeIn 0.5s ease forwards;
  }

  @keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    top: -60px;
    right: -60px;
    color: #496d20;
    font-size: 56px;
    font-weight: 300;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .close:hover,
  .close:focus {
    color: #273b10;
    text-decoration: none;
  }

  .backgroundBlue {
    width: 480px;
    height: 582px;
    top: 0px;
    background-color: #3FD0FF;
    position: absolute;
    justify-content: center;
    left: 0;
    z-index: 1;
  }

  .backgroundGreen {
    width: 480px;
    height: 3091px;
    top: 582px;
    position: absolute;
    left: 0;
    background-color: #68A025;
    justify-content: center;
    z-index: 1;
  }

  .cloud_left_mobile {
    width: 570.5px;
    height: 261px;
    top: 157.5px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: left, opacity;
    animation: slideCloudLeftMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudLeftMobile {
    0% {
      left: -637.5px;
      opacity: 0;
    }
    100% {
      left: -437.5px;
      opacity: 1;
    }
  }

  .cloud_right_mobile {
    width: 672.08px;
    height: 350.61px;
    top: 108px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: left, opacity;
    animation: slideCloudRightMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudRightMobile {
    0% {
      left: 505px;
      opacity: 0;
    }
    100% {
      left: 305px;
      opacity: 1;
    }
  }

  .cloud_top_mobile {
    width: 586px;
    height: 255.5px;
    left: -173px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
    will-change: top, opacity;
    animation: slideCloudTopMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideCloudTopMobile {
    0% {
      top: -270px;
      opacity: 0;
    }
    100% {
      top: -170px;
      opacity: 1;
    }
  }

  .bamboo_small_left_mobile {
    width: 237.32px;
    height: 469.73px;
    top: 145px;
    z-index: 2;
    will-change: left;
    position: absolute;
    pointer-events: none;
    animation: slideBambooSmallLeftMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooSmallLeftMobile {
    0% {
      left: -219px;
    }
    100% {
      left: -119px;
    }
  }

  .bamboo_small_right_mobile {
    width: calc(100% - 250px);
    height: 372.6px;
    position: absolute;
    top: 220px;
    z-index: 2;
    will-change: left;
    pointer-events: none;
    animation: slideBambooSmallRightMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooSmallRightMobile {
    0% {
      left: 439px;
      opacity: 0;
    }
    100% {
      left: 339px;
      opacity: 1;
    }
  }

  .bamboo_big_left_mobile {
    width: 304.99px;
    height: 3259.34px;
    top: 469.39px;
    z-index: 2;
    will-change: transform;
    position: absolute;
    pointer-events: none;
    animation: slideBambooBigLeftMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooBigLeftMobile {
    0% {
      left: -240px;
    }
    100% {
      left: -140px;
    }
  }

  .bamboo_big_right_mobile {
    width: calc(100% - 100px);
    height: 3259.47px;
    top: 525.19px;
    z-index: 2;
    will-change: transform;
    position: absolute;
    pointer-events: none;
    animation: slideBambooBigRightMobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  @keyframes slideBambooBigRightMobile {
    0% {
      left: 365.36px;
    }
    100% {
      left: 265.36px;
    }
  }

  .bridge-background-mobile {
    width: 480px;
    height: 122px;
    top: 555px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
  }

  .textureMiddleMobile {
    width: 480px;
    height: 3666.49px;
    top: 300px;
    z-index: 1;
    position: absolute;
    pointer-events: none;
  }

  .windowTrailerVideo {
    width: 278px;
    height: 159.7px;
    left: 72px;
    top: 1857.09px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
    transform-origin: center;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .windowTrailerVideo.visible {
    top: 1757.09px;
    opacity: 1;
  }

  .footer-mobile {
    width: 480px;
    height: 169px;
    position: absolute;
    left: 0px;
    z-index: 2;
    will-change: top;
    pointer-events: none;
    opacity: 0;
  }

  .footer-mobile.visible {
    animation: footer-mobile 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 1;
  }

  @keyframes footer-mobile {
    0% {
      top: 3504px;
    }
    100% {
      top: 3304px;
    }
  }
}
