@media screen and (min-width: 376px) and (max-width: 393px) {

  .roadMapQ3TextEng,
  .roadMapQ3TextCn,
  .roadMapQ4TextEng,
  .roadMapQ4TextCn,
  .roadMapQ1TextEng,
  .roadMapQ1TextCn,
  .p2eTextEng,
  .p2eTextCn {
    display: none;
  }

  .duriano-header {
    width: 325px;
    height: 52px;
    position: absolute;
    left: 34px;
    transform: translate(0%, 0px);
    animation: slideDurianoHeader 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: transform, opacity;
    pointer-events: none;
    z-index: 4;
  }

  @keyframes slideDurianoHeader {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 110px;
      opacity: 1;
    }
  }

  .p2eTextMobileEng {
    width: 262px;
    height: 28px;
    position: absolute;
    left: 65px;
    animation: slideDownP2eTextMobileEng 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes slideDownP2eTextMobileEng {
    0% {
      top: 0px;
      opacity: 0;
    }
    100% {
      top: 170px;
      opacity: 1;
    }
  }

  .p2eTextMobileCn {
    width: 216px;
    height: 28px;
    position: absolute;
    left: 89px;
    animation: slideDownP2eTextMobileCn 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  @keyframes slideDownP2eTextMobileCn {
    0% {
      top: 70px;
      opacity: 0;
    }
    100% {
      top: 170px;
      opacity: 1;
    }
  }

  .howToHeaderEng {
    width: 188px;
    height: 55px;
    position: absolute;
    left: 102px;
    top: 983px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .howToHeaderEng.visible {
    top: 883px;
    opacity: 1;
  }

  .howToHeaderCn {
    width: 188px;
    height: 55px;
    position: absolute;
    left: 102px;
    top: 983px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .howToHeaderCn.visible {
    top: 883px;
    opacity: 1;
  }

  .trailerHeaderEng {
    width: 184px;
    height: 55px;
    position: absolute;
    top: 1730px;
    left: 104px;
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .trailerHeaderEng.visible {
    top: 1630px;
    opacity: 1;
  }

  .trailerHeaderCn {
    width: 184px;
    height: 55px;
    position: absolute;
    top: 1730px;
    left: 104px;
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    will-change: top, opacity;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .trailerHeaderCn.visible {
    top: 1630px;
    opacity: 1;
  }

  .roadmapHeaderEng {
    width: 226px;
    height: 55px;
    position: absolute;
    top: 2061px;
    left: 83px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 3;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .roadmapHeaderEng.visible {
    top: 1961px;
    opacity: 1;
  }

  .roadmapHeaderCn {
    width: 226px;
    height: 55px;
    position: absolute;
    top: 2061px;
    left: 83px;
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 3;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .roadmapHeaderCn.visible {
    top: 1961px;
    opacity: 1;
  }

  .q3Header {
    width: 71px;
    height: 60px;
    position: absolute;
    left: 92px;
    top: 2304px;
    opacity: 0;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q3Header.visible {
    top: 2160px;
    opacity: 1;
  }

  .q4Header {
    width: 75px;
    height: 64px;
    position: absolute;
    left: 230px;
    top: 2811px;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q4Header.visible {
    top: 2425px;
    opacity: 1;
  }

  .q1Header {
    width: 80px;
    height: 54px;
    position: absolute;
    left: 95px;
    top: 3090px;
    transition: top 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: top, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .q1Header.visible {
    top: 2700px;
    opacity: 1;
  }

  .roadMapQ3TextMobileEng {
    width: 103px;
    height: 74px;
    position: absolute;
    left: 323px;
    top: 2215px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ3TextMobileEng.visible {
    left: 148px;
    opacity: 1;
  }

  .roadMapQ3TextMobileCn {
    width: 103px;
    height: 74px;
    position: absolute;
    left: 323px;
    top: 2215px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ3TextMobileCn.visible {
    left: 148px;
    opacity: 1;
  }

  .roadMapQ4TextMobileEng {
    width: 103px;
    height: 74px;  
    position: absolute;
    left: 0px;
    top: 2485px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ4TextMobileEng.visible {
    left: 145px;
    opacity: 1;
  }

  .roadMapQ4TextMobileCn {
    width: 103px;
    height: 74px;  
    position: absolute;
    left: 0px;
    top: 2485px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ4TextMobileCn.visible {
    left: 145px;
    opacity: 1;
  }

  .roadMapQ1TextMobileEng {
    width: 118px;
    height: 74px;
    position: absolute;
    top: 2760px;
    left: 0px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ1TextMobileEng.visible {
    left: 140px;
    opacity: 1;
  }

  .roadMapQ1TextMobileCn {
    width: 118px;
    height: 74px;
    position: absolute;
    top: 2760px;
    left: 0px;
    transition: left 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: left, opacity;
    pointer-events: none;
    z-index: 2;
  }

  .roadMapQ1TextMobileCn.visible {
    left: 140px;
    opacity: 1;
  }
}
